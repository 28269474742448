import * as cfg from './config.js';
import * as dateHelper from './DateHelper.js';

/**
 * Logs a message to the console if INFOLOGGING is on
 * @param {String} message - Log-Message
 */
export const logconsole = function (message, loglevel = 'INFO') {
  if (cfg.INFOLOGGING && loglevel === 'DEBUG') {
    console.log(`${loglevel}: ${message}`);
  }
  if (cfg.DEBUGLOGGING && (loglevel === 'INFO' || loglevel === 'DEBUG')) {
    console.log(`${loglevel}: ${message}`);
  }
};

/**
 * Gets a random value between 0 and maxVal
 * @param {Number} maxVal
 * @returns a random integer between 0 and maxVal
 */
export const getRandomInt = function (maxVal = 99999) {
  return Math.floor(Math.random() * maxVal);
};

/**
 * Starts a timer with the given label
 * @param {String} name - label of the timer to be started
 */
export const startTimer = function (name) {
  if (cfg.INFOLOGGING) {
    console.time(name);
  }
};

/**
 * Ends the timer with the given label
 * @param {String} name - label of the timer to be ended
 */
export const endTimer = function (name) {
  if (cfg.INFOLOGGING) {
    console.timeEnd(name);
  }
};

/**
 * Function checks if file ending is csv. Returns true if yes, false otherwise
 * @param {String} fileName
 */
export const checkIsCSVFile = function (fileName) {
  const file = fileName.split('.');
  if (file.length === 1) return false;
  const end = file[file.length - 1];
  if (end === 'csv') {
    return true;
  }
};

/**
 * Gets the earliest Date from the given data-array
 * @param {Array} data - stakingdata array
 * @returns the earliest data from array
 */
export const getStartDate = function (data) {
  const dateArr = [];
  let i = 0;
  data.forEach(el => {
    dateArr.push(parseInt((new Date(el.Date).getTime() / 1000).toFixed(0)));
  });
  dateArr.sort((a, b) => a - b);
  return dateArr[0];
};
/**
 * Gets the latest Date from the given data-array
 * @param {Array} data - stakingdata array
 * @returns the latest data from array
 */
export const getEndDate = function (data) {
  const dateArr = [];
  data.forEach(el => {
    dateArr.push(parseInt((new Date(el.Date).getTime() / 1000).toFixed(0)));
  });
  dateArr.sort((a, b) => b - a);
  return dateArr[0];
};

/**
 * Filters the data array for the given coinId
 * @param {Array} data - The complete data array from imported files
 * @param {String} coinId - String Id of the coin to be separated
 * @returns Array - Array with all elements of coinId
 */
export const getDataForCoin = function (data, coinId) {
  return data.filter(el => el.Cryptocurrency === coinId);
};

///////////////////////////////////////////////////////////////////////////////
/////GENERAL FUNCTIONS FOR CSV AND OBJECT BASED REPORTS////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

const filterDataByDate = function (data, date, group) {
  let retArr = [];
  data.forEach(elArr => {
    elArr.forEach(el => {
      if (group === 'day') {
        if (!dateHelper.isDateEqualDailyBase(el.Date, date)) {
          retArr.push(el);
        }
      } else if (group === 'month') {
        if (!dateHelper.isDateEqualMonthlyBase(el.Date, date)) {
          retArr.push(el);
        }
      }
    });
  });
  return retArr;
};

/**
 * Checks if there are possible incomplete data because of grouping
 * @param {Array} data - Complete data to be checked
 * @param {Date} fileDate - The date of the given file
 * @param {String} group - The type of data grouping
 */
export const checkAndCorrectData = function (data, fileDate, group) {
  //Check, if Data contains entries with file date
  //1. Is grouping none --> everything is fine
  //2. Is grouping Day --> Delete all groupable entries with filedate
  //3. Is grouping Montag --> Delete all groupable entries with same month as filedate

  const groupArr = getGroupableData(data);
  let retArr = filterDataByDate(groupArr, fileDate, group);
  const enterStaking = [];
  enterStaking.push(data.filter(el => el.Operation === 'Entry staking wallet'));
  retArr.push(...filterDataByDate(enterStaking, fileDate, group));
  const restArr = getNonGroupableData(data);
  retArr.push(...restArr);

  return retArr;
};

/**
 * Boils down an given array to a single object
 * @param {Array} groupData - Array with elements to be grouped
 * @param {String} newOpId - If a new Operation ID is needed
 */
export const getGroupedEntry = function (groupData, newOpId) {
  let amountSum = 0;
  let valueSum = 0;
  let valueSumAPI = 0;
  let opCode = newOpId;
  groupData.forEach(elem => {
    amountSum += elem.Amount;
    valueSum += elem['FiatValue'];
  });
  if (newOpId.length === 0) {
    opCode = groupData[0].Operation;
  }
  let tempDate = groupData[0].Date;
  //Set group data to 00:01:00
  tempDate.setHours(0, 1, 0);

  return {
    Date: tempDate,
    Month: groupData[0].Date.getMonth() + 1,
    Operation: opCode,
    Cryptocurrency: groupData[0].Cryptocurrency,
    Amount: amountSum,
    Value: valueSum,
    Currency: groupData[0]['FiatCurrency'],
  };
};

/**
 * Returns an Array with arrays of groupable actions
 * @param {Array} coinData - Data to be filtered
 * @returns the Array with arrays of groupable actions
 */
export const getGroupableData = function (coinData) {
  const retArr = [];
  cfg.GROUPABLE_ACTIONS.forEach(action => {
    const arr = coinData.filter(el => {
      if (el.Operation.indexOf(action) !== -1) {
        return true;
      }
      return false;
    });
    if (arr.length > 0) {
      retArr.push(arr);
    }
  });
  return retArr;
};

/**
 * Gets an array with all non-groupable transaction ids
 * @param {Array} coinData - Array with the data
 * @returns the non-groupable actions as an array
 */
export const getNonGroupableData = function (coinData) {
  return coinData.filter(el =>
    checkActions(cfg.NON_GROUPABLE_ACTIONS, el.Operation)
  );
};

/**
 * Removes dublicate objects from array and returns the reduced array
 * @param {Array} inData - Array with objects
 */
export const removeDublicates = function (inData) {
  let rewArr = [];
  let retArr = [];
  let comArr = [];
  let i = 0;
  for (i = 0; i < inData.length; i++) {
    //Don´t check the rewards --> big amount and no dublicate entries!
    if (inData[i].Operation === 'Rewards') {
      rewArr.push(inData[i]);
    } else if (inData[i].Operation === 'Commission') {
      if (inData[i].PotentialDublicate === true) {
        let index = retArr.findIndex(el => {
          if (
            el.Amount === inData[i].Amount &&
            el.Cryptocurrency === inData[i].Cryptocurrency &&
            el.Block === inData[i].Block &&
            el.Owner === inData[i].Owner &&
            el.Operation === inData[i].Operation &&
            el.PoolID === inData[i].PoolID &&
            el.OrigAmount === inData[i].OrigAmount
          ) {
            logconsole(
              `Found dublicate: Block: ${el.Block} - OP: ${el.Operation} - Owner: ${el.Owner} - OrigAmount: ${el.OrigAmount}`
            );
            return true;
          } else {
            return false;
          }
        });
        if (index === -1) {
          retArr.push(inData[i]);
        }
      } else {
        comArr.push(inData[i]);
      }
    } else {
      let index = retArr.findIndex(el => {
        if (
          el.Amount === inData[i].Amount &&
          el.Cryptocurrency === inData[i].Cryptocurrency &&
          el.Block === inData[i].Block &&
          el.Owner === inData[i].Owner &&
          el.Operation === inData[i].Operation &&
          el.PoolID === inData[i].PoolID
        ) {
          logconsole(
            `Found dublicate: Block: ${el.Block} - OP: ${el.Operation} - Owner: ${el.Owner}`
          );
          return true;
        } else {
          return false;
        }
      });
      if (index === -1) {
        retArr.push(inData[i]);
      }
    }
  }
  retArr = [...retArr, ...rewArr, ...comArr];
  return retArr;
};

/**
 *
 * @param {*} arrElems
 * @param {*} searchElem
 * @returns
 */
export const checkActions = function (arrElems, searchElem) {
  let bFound = false;

  arrElems.forEach(el => {
    if (searchElem.indexOf(el) !== -1 && el.length > 0) {
      bFound = true;
    }
  });

  return bFound;
};

/**
 * Checks the given array for unsupported transaction ids
 * @param {Array} data
 * @returns an error string if there are unsupported transactions
 */
export const checkUnsupportedTransactions = function (data) {
  let strAlert = '';
  const ergArr = data.filter(el => {
    if (
      checkActions(cfg.GROUPABLE_ACTIONS, el.Operation) ||
      checkActions(cfg.NON_GROUPABLE_ACTIONS, el.Operation) ||
      checkActions(cfg.SPECIAL_ACTIONS, el.Operation)
    ) {
      return false;
    } else {
      return true;
    }
  });
  //Generate a list with all unsupported actions
  const unsup = new Set();
  ergArr.forEach(el => {
    unsup.add(el.Operation);
  });
  if (unsup.size > 0) {
    strAlert += `WARNING: The following transactions are actually not supported:\n`;
    unsup.forEach(el => {
      const entry = ergArr.find(elem => elem.Operation === el);
      strAlert += `>> Id:"${entry.Operation}" Amount:"${entry.Amount}" Currency:"${entry.Cryptocurrency}"<<\n`;
    });
    strAlert += `\nPlease send this as a screenshot to Marcus to help improving this website!\nThank you!!!`;
  }
  return strAlert;
};

/**
 * Builds the csv line with the given data for the given parameters
 * @param {String} type
 * @param {Number} buyAmount
 * @param {String} buyCur
 * @param {Number} sellAmount
 * @param {String} sellCur
 * @param {Number} feeAmount
 * @param {String} feeCur
 * @param {String} exchange
 * @param {String} tradeGroup
 * @param {String} comment
 * @param {Date} date
 * @param {String} txId
 * @param {String} clarification
 * @param {String} toolid
 * @returns the csv line for the output
 */
export const getCSVLine = function (
  cointracking_id,
  blockpit_id,
  buyAmount,
  buyCur,
  sellAmount,
  sellCur,
  feeAmount,
  feeCur,
  exchange,
  tradeGroup,
  comment,
  date,
  txId,
  toolid,
  tx_counter
) {
  let csvString = '';
  if (toolid === 'Cointracking') {
    if (cointracking_id === 'Other Fee') {
      sellAmount = feeAmount;
      sellCur = feeCur;
    }
    csvString += `"${cointracking_id}","${buyAmount}","${cfg.COINTRACKING_TOKEN_MAP.get(
      buyCur
    )}","${sellAmount}","${cfg.COINTRACKING_TOKEN_MAP.get(
      sellCur
    )}","${feeAmount}","${cfg.COINTRACKING_TOKEN_MAP.get(
      feeCur
    )}","${exchange}","${tradeGroup}","${comment}","${dateHelper.getDateStringCointracking(
      date
    )}", "${txId}"\n`;
  }
  //New Blockpit version
  if (toolid === 'Blockpit') {
    if (blockpit_id === 'gift') {
      if (sellCur === '') {
        sellCur = feeCur;
        sellAmount = feeAmount;
        feeCur = '';
        feeAmount = '';
      }
    }
    csvString += `${tx_counter},csv_import,${exchange},${dateHelper.getDateString(
      date
    )},${cfg.BLOCKPIT_TOKEN_MAP.get(
      buyCur
    )},${buyAmount},${cfg.BLOCKPIT_TOKEN_MAP.get(
      sellCur
    )},${sellAmount},${cfg.BLOCKPIT_TOKEN_MAP.get(
      feeCur
    )},${feeAmount},${blockpit_id},${comment},\n`;
  }

  if (toolid === 'Koinly') {
    if (cointracking_id === 'Other Fee') {
      sellAmount = feeAmount;
      feeAmount = 0;
      sellCur = feeCur;
      feeCur = 0;
    }
    //Date,Sent Amount,Sent Currency,Received Amount,Received Currency,Fee Amount,Fee Currency,Net Worth Amount,Net Worth Currency,Label,Description,TxHash
    csvString += `${dateHelper.getDateString(
      date
    )},${sellAmount},${cfg.KOINLY_TOKEN_MAP.get(
      sellCur
    )},${buyAmount},${cfg.KOINLY_TOKEN_MAP.get(
      buyCur
    )},${feeAmount},${cfg.KOINLY_TOKEN_MAP.get(
      feeCur
    )},,,${tradeGroup},${comment},${txId}\n`;
  }
  return csvString;
};
