function Sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/**
 * Function gets the price at the given date for the given coin in the given currency
 * @param {string} coinId - The coingecko string for the crypto asset (e.g. bitcoin, ethereum, dash, defichain, zcoin)
 * @param {Date} date - DD-MM-YYYY
 * @param {string} currency - The FIAT-currency (eur, usd). Default is eur
 */
export const getHistoricalPriceForCoin = async function (
  coinId,
  histDate,
  currency = 'eur'
) {
  try {
    const historicalData = `https://api.coingecko.com/api/v3/coins/${coinId}/history?date=${histDate}`;
    const resHistorical = await fetch(historicalData);
    if (!resHistorical.ok)
      throw new Error(`Problem getting price data!\n ${resHistorical.ok}`);
    const dataHistorical = await resHistorical.json();
    return dataHistorical.market_data.current_price[currency];
  } catch (err) {
    console.error(err);
    alert(`Something went wrong\n ${err.message}`);
    throw err;
  }
};

/**
 * Function gets the historical data in the given date range for the given FIAT-currency and the given crypto currency
 * @param {string} coinId - The coingecko string for the crypto asset (e.g. bitcoin, ethereum, dash, defichain, zcoin)
 * @param {Date} startDate - in seconds since 1970
 * @param {Date} endDate - in seconds since 1970
 * @param {string} currency - The FIAT-currency (eur, usd). Default is eur
 */
export const getHistoricalDataForCoin = async function (
  coinId,
  startDate,
  endDate,
  currency = 'eur'
) {
  try {
    await Sleep(10000);
    const historicalData = `https://api.coingecko.com/api/v3/coins/${coinId}/market_chart/range?vs_currency=${currency}&from=${startDate}&to=${endDate}`;
    const resHistorical = await fetch(historicalData);
    if (!resHistorical.ok)
      throw new Error(`Problem getting price data!\n ${resHistorical.ok}`);
    const dataHistorical = await resHistorical.json();
    return dataHistorical;
  } catch (err) {
    console.error(err);
    alert(`Something went wrong\n ${err.message}`);
    throw err;
  }
};
