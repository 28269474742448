import * as cfg from '../Helper/config.js';
import * as ocean from '../../API/OceanAPI.js';
import * as dateHelper from '../Helper/DateHelper.js';
let cachedPoolPairs = [];
let cachedLastPoolpairApiCall = 0;

/**
 * Gets the price of the given coin in currency from the oracle
 * @param {String} coin - Coin for which the price should be retrieved
 * @param {String} currency - Fiat curreny
 * @returns the Fiat value of the given coin
 */
export const getCoinPrice = async function (coin, currency) {
  try {
    let priceCoin;
    if (coin === 'DUSD-USD') {
      priceCoin = 1;
    } else {
      priceCoin = await ocean.getTokenPrice(`${coin}`);
    }

    if (currency === 'eur') {
      const eurusd = await ocean.getTokenPrice('EUR-USD');
      priceCoin = priceCoin / eurusd;
    }

    return priceCoin;
  } catch (err) {
    console.error(err);
    return NaN;
  }
};

/**
 * Calculates the given USD Value to given currency
 * @param {Number} value - Price to be converted
 * @param {String} currency - The target currency
 * @returns
 */
export const calcValueToCurrency = async function (value, currency) {
  try {
    let priceCoin;
    if (currency === 'eur') {
      const eurusd = await ocean.getTokenPrice('EUR-USD');
      priceCoin = value / eurusd;
    } else {
      priceCoin = value;
    }
    return priceCoin;
  } catch (err) {
    console.error(err);
    return NaN;
  }
};

/**
 * Calculates the price of the liquidity token in currency
 * @param {String} token - Token to be calculated
 * @param {String} currency - Fiat currency (eur or usd)
 * @returns the Fiat price for the given token
 */
export const getLMTokenPrice = async function (token, currency) {
  try {
    const dateCheck = Date.now();
    //Split Token
    const coins = token.split('-');
    const priceCoin1 = await getCoinPrice(`${coins[0]}-USD`, 'USD');
    //Get PoolPair-Infos
    let poolPairs;
    if (dateCheck - cachedLastPoolpairApiCall < 120000) {
      poolPairs = cachedPoolPairs;
    } else {
      poolPairs = await ocean.getPoolPairInfos();
      cachedPoolPairs = poolPairs;
      cachedLastPoolpairApiCall = dateCheck;
    }
    const poolId = cfg.poolIdMap.get(coins[0]);
    const elem = poolPairs.find(el => el.id === poolId);

    let tokenPrice = elem.totalLiquidity.usd / elem.totalLiquidity.token;
    //console.log(tokenPrice);
    tokenPrice = calcValueToCurrency(tokenPrice, currency);

    return tokenPrice;
  } catch (err) {
    console.error(err);
    return NaN;
  }
};

/**
 * Gets the prices for the liquidity token
 * @param {String} cur -  Fiat currency for getting the price feed
 * @returns
 */
export const getLMPriceFeed = async function (cur) {
  const priceList = [];
  for (let i = 0; i < cfg.POOLS.length; i++) {
    let entry = {};
    entry.ticker = cfg.POOLS[i];
    entry.price = await getLMTokenPrice(entry.ticker, cur);
    priceList.push(entry);
  }
  return priceList;
};

/**
 * Checks if a given date is in given data (on hourly base)
 * @param {Array} data - Data to be investigated
 * @param {Date} date - Date to be checked
 * @returns true if found, false otherwise
 */
const checkIfDateIsInData = function (data, date) {
  let val = false;
  data.forEach(el => {
    if (dateHelper.isDateEqualHourlyBase(new Date(el.time * 1000), date)) {
      val = true;
    }
  });
  return val;
};

/**
 * Gets the historical price in USD for the given token
 * @param {String} token - Name of the Stock Token
 * @param {Date} date - date in history
 */
export const getHistoricalDataForToken = async function (token, date) {
  console.log(`Get historical prices for ${token}...`);
  console.time('Took');
  let ergArr = await ocean.getHistoricalTokenPrice(
    `${token}-USD`,
    cfg.STOCKTOKEN_ORACLES.get(token)
  );
  let checkDate = new Date(date * 1000);

  let arrErg = ergArr.data;
  if (checkIfDateIsInData(ergArr.data, checkDate)) {
    console.timeEnd('Took');
    return arrErg;
  }
  let nextPage = ergArr.page;

  for (let i = 0; i < 200000; i++) {
    let newErg = await ocean.getNextPage(nextPage.next);
    arrErg = arrErg.concat(newErg.data);
    if (checkIfDateIsInData(newErg.data, checkDate)) {
      console.timeEnd('Took');
      return arrErg;
    }

    nextPage = newErg.page;
    if (!nextPage) {
      break;
    }
  }
  console.timeEnd('Took');
  return arrErg;
};
