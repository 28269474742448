import * as fproc from './Files/FileProcessor.js';
import * as cfg from './Helper/config.js';
import * as api from '../API/coingecko.js';
import * as blockInfo from '../API/BlockInfo.js';
import * as CakeTrades from './Trades/CakeTrades.js';
import * as DeFiTrades from './Trades/DeFiTrades.js';
import * as CakeLM from './LiquidityMining/CakeLM.js';
import * as DeFiLM from './LiquidityMining/DeFiLM.js';
import * as DeFiVaults from './Vaults/DefiVaults.js';
import * as CakeVaults from './Vaults/CakeVaults.js';
import * as Staking from './Staking/CakeStaking.js';
import * as Actions from './Actions/Actions.js';
import * as hlp from './Helper/Helper.js';
import * as overViewHelper from './Summary/Summary.js';
import * as dateHelper from './Helper/DateHelper.js';
import * as tokenPrice from './Pricing/TokenPrices.js';
import * as dfitaxAPI from '../API/dfitax.js';
import * as adrParser from './Addresses/AddressParser.js';
import * as DeFiStockSplits from './StockSplits/DeFiStockSplits.js';
import * as CakeStockSplits from './StockSplits/CakeStockSplits.js';
import * as CakeEarn from './Earn/CakeEarn.js';
import * as FutureSwaps from './FutureSwap/DeFiFutureSwaps.js';
import * as Auctions from './Auctions/DeFiAuctions.js';
/**
 * Object which saves the state of the application
 */
export const state = {
  currency: 'eur',
  files: [],
  fileCount: 0,
  completeData: [],
  fiatCur: '',
  fiatCurApi: '',
  coinDataArray: [],
  sumArray: [],
  csvReport: '',
  directBlockchain: '',
  useToken: false,
  depotlabel: 'auto',
  uniqueCOINS: new Set(),
  uniqueTOKENS: new Set(),
};

export const clearState = function () {
  state.currency = 'eur';
  state.files = [];
  state.fileCount = 0;
  state.completeData = [];
  state.fiatCur = '';
  state.fiatCurApi = '';
  state.coinDataArray = [];
  state.sumArray = [];
  state.csvReport = '';
  state.directBlockchain = false;
  state.useToken = true;
  state.depotlabel = 'auto';
  state.uniqueCOINS = new Set();
  state.uniqueTOKENS = new Set();
};

export const COIN_PRICEFEED = new Map();
export const TOKEN_PRICEFEED = new Map();

/**
 * Builds up an obj with the historical data for all coins which are supported by defichain / cake
 */
export const getPriceFeed = async function () {
  console.log('Getting Price feed for the Crypto-Token...');
  console.time('Duration:');
  //Get start and end date for the data to be processed
  let startDate = hlp.getStartDate(state.completeData) - 86400;
  const endDate = hlp.getEndDate(state.completeData) + 86400;

  //console.log(`Start End: ${startDate} - ${endDate}`);
  //If the timerange is smaller than 90 days, the pricefeed is on a hourly basis which is not working for now with the algorithm
  if (endDate - startDate < 7776000) {
    startDate = startDate - (7776000 - (endDate - startDate)) - 86400;
  }
  //console.log(`Start End: ${startDate} - ${endDate}`);
  for (const element of state.uniqueCOINS) {
    const result = await api.getHistoricalDataForCoin(
      cfg.coinIdMap.get(element),
      startDate,
      endDate,
      state.currency
    );
    const datePrice = new Map();
    result.prices.forEach(entry => {
      datePrice.set(entry[0], entry[1]);
    });
    COIN_PRICEFEED.set(element, datePrice);
  }
  console.timeEnd('Duration:');
  console.log('Getting Price feed for the Crypto-Token...done');
};

/**
 * Gets the token price feed for the complete dataset
 */
export const getTokenPriceFeed = async function () {
  console.log('Getting Price feed for the STOCK-Token...');
  console.time('Duration');
  //Get start and end date for the data to be processed
  let startDate = hlp.getStartDate(state.completeData) - 86400;

  for (let item of state.uniqueTOKENS) {
    let erg = await tokenPrice.getHistoricalDataForToken(item, startDate);

    const datePrice = new Map();
    erg.forEach(entry => {
      let chkDate = new Date(entry.time * 1000);
      if (chkDate.getHours() === 23) {
        chkDate.setUTCHours(0, 0, 0, 0);
        datePrice.set(chkDate.getTime() / 1000, entry.amount);
      }
    });
    TOKEN_PRICEFEED.set(item, datePrice);
  }
  console.timeEnd('Duration');
  console.log('Getting Price feed for the STOCK-Token...done');
};
/**
 * Helper function to get the price for a given time stamp
 * @param {String} coinId - Coin id for the requested coin
 * @param {Date} date - Date object for the requested time
 * @returns the price for the given data
 */
export const getPriceForDate = function (coinId, date) {
  //console.log(`Searching for ${coinId}.Price on ${date}`);
  let erg;
  let chkDate = date.getTime() / 1000;
  if (cfg.COINLIST.includes(coinId)) {
    erg = COIN_PRICEFEED.get(coinId).get(date.getTime());
  } else {
    if (coinId === 'DUSD') {
      if (state.currency === 'eur') {
        let eurusd = Number(TOKEN_PRICEFEED.get('EUR').get(chkDate));
        if (!eurusd) {
          eurusd = Number(TOKEN_PRICEFEED.get('EUR').get(chkDate - 86400));
        }
        erg = 1 / eurusd;
      } else {
        erg = 1;
      }
    } else {
      erg = TOKEN_PRICEFEED.get(coinId).get(chkDate);
      if (!erg) {
        erg = TOKEN_PRICEFEED.get(coinId).get(chkDate - 86400);
        if (!erg) {
          erg = TOKEN_PRICEFEED.get(coinId).get(chkDate - 86400 - 86400);
          if (!erg) {
            erg = TOKEN_PRICEFEED.get(coinId).get(
              chkDate - 86400 - 86400 - 86400
            );
          }
        }
      }
      if (state.currency === 'eur') {
        let eurusd = Number(TOKEN_PRICEFEED.get('EUR').get(chkDate));
        if (!eurusd) {
          eurusd = Number(TOKEN_PRICEFEED.get('EUR').get(chkDate - 86400));
        }
        erg = erg / eurusd;
      }
    }
  }

  if (!erg) {
    //console.log(`Not found at date ${date.getTime()} for ${coinId} `);
    if (date.getTime() < 1593554400000) {
      if (coinId === 'DFI') {
        if (state.currency === 'usd') {
          erg = Number(0.1);
        } else if (state.currency === 'chf') {
          erg = Number(0.094);
        } else {
          erg = Number(0.089);
        }
      }
    }
  }
  return erg;
};

/**
 *
 * @param {Array} addresses
 */
export const processAddresses = async function (
  addresses,
  grouping,
  currency,
  yearOfReport
) {
  let completeData = [];
  state.fileCount = addresses.length;
  if (state.fileCount === 0) {
    alert('Please enter addresses first');
    return;
  }
  console.log(
    `Processing addresses: ${addresses} on ${grouping} in ${currency}`
  );
  //1.) Add and check all addresses
  /*let adrValidState = true;
  let adrPresentState = true;
  for (let adr of addresses) {
    let erg = await dfitaxAPI.addAndCheckAddressDFITAX(adr);
    adrValidState = erg.valid && adrValidState;
    adrPresentState = erg.present && adrPresentState;
  }
  if (adrValidState) {
    console.log('All addresses are valid!');
  }
  if (adrPresentState) {
    console.log('All data ready');
  }
  if (!adrValidState || !adrPresentState) {
    return false;
  }*/

  if (grouping === 'none') {
    grouping = 'day';
  }
  //2.) Get the rewards from DFI.TAX
  let ergAPI = await dfitaxAPI.getDFITAXRewards(addresses, grouping, currency);

  //2.a.) Parse the result from the API call and fill up the data structure
  completeData = completeData.concat(
    adrParser.parseDfiTaxRewardAPICall(ergAPI, currency)
  );

  //3.) Get the history from DFI.TAX and parse the results

  let ergHistAPI = await dfitaxAPI.getDFITAXHistory(
    addresses,
    yearOfReport,
    currency
  );
  completeData = completeData.concat(
    adrParser.parseDfiTaxHistoryAPICall(ergHistAPI, currency)
  );

  //Save all data in state-object
  state.completeData = completeData;
  state.fiatCur = currency;
  fillAssetList();
  return true;
};

/**
 * Loads and processes all given files asynchronously
 * @param {*} files - Array of files to be read in
 */
export const loadFiles = async function (files) {
  try {
    if (files) {
      let defiCounter = 0;
      hlp.startTimer('Loaded files in');
      for (let i = 0; i < files.length; i++) {
        let fInfo = {};
        const file = files[i];
        fInfo.name = file.name;
        fInfo.size = file.size;
        fInfo.type = file.type;
        fInfo.lastModified = file.lastModified;
        let result = await fproc.readFile(file);
        hlp.logconsole('Loaded!');
        fInfo.typeOfFile = fproc.checkFileType(result.target.result);

        //ProcessFiles
        let erg = fproc.processFile(result.target.result, fInfo.typeOfFile);

        //Check if is old DEFIAPP_FILE
        if (fInfo.typeOfFile === cfg.DEFIAPP_FILE) {
          hlp.logconsole('Adding Dates because of old DefiApp-File');
          await blockInfo.addDateToData(erg);
        }
        if (
          fInfo.typeOfFile === cfg.DEFIAPP_FILE ||
          fInfo.typeOfFile === cfg.DEFIAPP_FILE_231 ||
          fInfo.typeOfFile === cfg.DEFIAPP_FILE_232
        ) {
          defiCounter++;
        }

        fInfo.data = erg;
        state.files.push(fInfo);
      }
      state.fileCount = files.length;
      let completeData = [];

      if (defiCounter > 1) {
        let defiData = [];
        state.files.forEach(el => {
          if (
            el.typeOfFile === cfg.DEFIAPP_FILE ||
            el.typeOfFile === cfg.DEFIAPP_FILE_231 ||
            el.typeOfFile === cfg.DEFIAPP_FILE_232
          ) {
            defiData = defiData.concat(el.data);
          } else {
            completeData = completeData.concat(el.data);
          }
        });
        let remDubl = hlp.removeDublicates(defiData);
        completeData = completeData.concat(remDubl);
      } else {
        state.files.forEach(el => {
          completeData = completeData.concat(el.data);
        });
      }
      state.completeData = completeData;
      if (completeData.length > 0) {
        state.fiatCur = completeData[0].FiatCurrency;
      } else {
        state.fiatCur = 'eur';
      }

      fillAssetList();

      hlp.endTimer('Loaded files in');
    }
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};

export const fillAssetList = function () {
  let isDUSD = false;
  state.completeData.forEach(el => {
    //FIXME: Dirty hack for handling CAKE-Version (dTOKEN) and DeFiChain-Version (Token)
    let checkToken = el.Cryptocurrency;
    if (checkToken[0] === 'd') {
      el.Cryptocurrency = el.Cryptocurrency.slice(1);
    }
    if (cfg.STOCKTOKEN.includes(el.Cryptocurrency)) {
      if (el.Cryptocurrency != 'DUSD') {
        state.uniqueTOKENS.add(el.Cryptocurrency);
      } else {
        isDUSD = true;
      }
    } else {
      if (el.Cryptocurrency.indexOf('-') === -1) {
        state.uniqueCOINS.add(el.Cryptocurrency);
      }
    }
  });
  if (state.uniqueTOKENS.size > 0 || isDUSD === true) {
    state.uniqueTOKENS.add('EUR');
  }
};

/**
 * Separates the complete data in different arrays for each coin
 */
export const separateCoins = function () {
  try {
    //1.) Separate Data for each coin
    const coinDataArray = [];
    cfg.COINLIST.forEach(coin => {
      coinDataArray.push(hlp.getDataForCoin(state.completeData, coin));
    });
    cfg.STOCKTOKEN.forEach(token => {
      coinDataArray.push(hlp.getDataForCoin(state.completeData, token));
    });
    cfg.LPTOKEN.forEach(lp => {
      coinDataArray.push(hlp.getDataForCoin(state.completeData, lp));
    });

    state.coinDataArray = coinDataArray;
    state.fiatCurApi = '';
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};

/**
 * Generates a summary of all earnings
 * @param {String} yearOfReport
 */
export const generateSummaryData = function (yearOfReport) {
  try {
    //1.) Check the requested report year
    let year = 2021;
    if (yearOfReport !== 'All') {
      year = Number(yearOfReport);
    }

    //2.) Build up the reward array
    const sumArray = [];
    for (let i = 0; i < state.coinDataArray.length; i++) {
      sumArray.push(overViewHelper.sumRewards(state.coinDataArray[i], year));
    }

    //3.) Store the summary in the state object
    state.sumArray = sumArray;
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};

/**
 * Check and returns the information about unsupported transactions
 * @returns the informationen if there are any unsupported transactions
 */
export const getUnsupportedTransactions = function () {
  return hlp.checkUnsupportedTransactions(state.completeData);
};

/**
 * Generates the csv-Report for the csv-based Cointracking Tools
 * @param {String} reportTool - Id of the cointracking to be used
 * @param {String} yearOfReport - The year for the report
 * @param {*String)
 */
export const generateCointrackerReport = async function () /*reportTool,
  yearOfReport,
  groupingType*/
{
  try {
    const tool = state.reportTool;
    const yearOfReport = state.yearOfReport;
    const groupingType = state.groupingType;
    const useToken = state.useToken;
    //0.) Check and report unsupported Transactions
    let reportData = [];
    let csvReport = '';
    let objReport = [];

    //1.) Get the elements for "yearOfReport"
    if (yearOfReport !== 'All') {
      reportData = dateHelper.getElementsByYear(
        state.completeData,
        yearOfReport
      );
    } else {
      reportData = state.completeData;
    }
    //2.) Check and correct data if grouping is day or month (incomplete data!)
    if (groupingType !== 'none') {
      let checkDate;
      if (state.directBlockchain) {
        checkDate = new Date(Date.now());
      } else {
        checkDate = new Date(state.files[0].lastModified);
      }

      reportData = hlp.checkAndCorrectData(reportData, checkDate, groupingType);
      state.completeData = reportData;
    }

    //console.log(state.completeData);
    //3.) -----REPORT-----
    //1.a.) Generate Internal Trade Actions
    objReport = objReport.concat(
      CakeTrades.generateCakeTrades(state.completeData)
    );
    //1.b.) Generate Internal Trade Actions (new version with DEX & error in CAKE Export)
    objReport = objReport.concat(
      CakeTrades.generateCakeTrades2(state.completeData)
    );
    //console.log('Trades');
    //console.log(objReport);

    objReport = objReport.concat(
      DeFiTrades.generateDeFiTrades(state.completeData)
    );

    //2.) Generate Liquidity Mining actions
    objReport = objReport.concat(
      await CakeLM.generateCakeLMActions(state.completeData, useToken, tool)
    );
    //console.log('LM');
    //console.log(objReport);
    objReport = objReport.concat(
      await DeFiLM.generateDeFiLMActions(state.completeData, useToken, tool)
    );

    // Generate DeFiVaults actions
    objReport = objReport.concat(
      DeFiVaults.generateDeFiVaultsActions(state.completeData)
    );

    // Generate DeFiVaults actions
    objReport = objReport.concat(
      CakeVaults.generateCakeVaultsActions(state.completeData)
    );

    //Generate CAKE Staking actions
    objReport = objReport.concat(
      Staking.generateStakingCakeActions(state.completeData, groupingType, tool)
    );

    //Generate CAKE Earn actions
    objReport = objReport.concat(
      CakeEarn.generateEarnCakeActions(state.completeData, groupingType)
    );

    // Generate StockSplit actions
    objReport = objReport.concat(
      DeFiStockSplits.generateDeFiStockSplits(state.completeData)
    );
    // Generate StockSplit actions
    objReport = objReport.concat(
      CakeStockSplits.generateCakeStockSplits(state.completeData)
    );

    CakeStockSplits;
    // Generate FutureSwap actions
    objReport = objReport.concat(
      FutureSwaps.generateFutureSwaps(state.completeData)
    );

    // Generate Auction actions
    objReport = objReport.concat(
      Auctions.generateDeFiAuctionActions(state.completeData)
    );

    //e.) Separate Coins
    separateCoins();
    //console.log(state.coinDataArray);
    //f.) Generate actions for each coin
    state.coinDataArray.forEach(coinData => {
      if (coinData.length > 0) {
        objReport = objReport.concat(
          Actions.generateActions(coinData, groupingType)
        );
      }
    });

    objReport.sort((a, b) => b.date.getTime() - a.date.getTime());
    //console.log(objReport);

    if (tool !== 'Accointing') {
      csvReport += cfg.CSVHEADER.get(tool);
      let txCounter = 0;

      objReport.forEach(el => {
        txCounter++;
        let exchangeName = '';
        if (state.directBlockchain) {
          exchangeName =
            state.depotlabel === 'auto'
              ? cfg.DEPOT_LABEL.get('defichainlw')
              : cfg.DEPOT_LABEL.get(state.depotlabel);
          if (el.exchange.search(' - ') != -1) {
            exchangeName =
              exchangeName + el.exchange.slice(el.exchange.search(' - '));
          }
        } else {
          exchangeName =
            state.depotlabel === 'auto'
              ? el.exchange
              : cfg.DEPOT_LABEL.get(state.depotlabel);
          if (state.depotlabel != 'auto') {
            if (el.exchange.search(' - ') != -1) {
              exchangeName =
                exchangeName + el.exchange.slice(el.exchange.search(' - '));
            }
          }
        }

        csvReport += hlp.getCSVLine(
          el.cointracking_type,
          el.blockpit_type,
          el.buyAmount,
          el.buyCur,
          el.sellAmount,
          el.sellCur,
          el.feeAmount,
          el.feeCur,
          exchangeName,
          el.tradeGroup,
          el.comment,
          el.date,
          el.txId,
          tool,
          txCounter
        );
      });

      const testStr = csvReport.charAt(csvReport.length - 1);
      if (testStr === '\n') {
        csvReport = csvReport.substr(0, csvReport.length - 1);
      }
      state.csvReport = csvReport;
    } else {
      const accointingWorkbook = XLSX.utils.book_new();
      const accointingHeader = {
        header: [
          'transactionType',
          'date',
          'inBuyAmount',
          'inBuyAsset',
          'outSellAmount',
          'outSellAsset',
          'feeAmount (optional)',
          'feeAsset (optional)',
          'classification (optional)',
          'operationId (optional)',
          'comments (optional)',
        ],
      };
      const newObjReport = [];

      objReport.forEach(el => {
        let exchangeName = '';
        if (state.directBlockchain) {
          exchangeName =
            state.depotlabel === 'auto'
              ? cfg.DEPOT_LABEL.get('defichainlw')
              : cfg.DEPOT_LABEL.get(state.depotlabel);
        } else {
          exchangeName =
            state.depotlabel === 'auto'
              ? el.exchange
              : cfg.DEPOT_LABEL.get(state.depotlabel);
        }
        newObjReport.push(
          Actions.getObjectFromData(
            el.accointing_type,
            el.date,
            el.buyAmount,
            el.buyCur,
            el.sellAmount,
            el.sellCur,
            el.feeAmount,
            el.feeCur,
            el.accointing_clarification,
            `${exchangeName}: ${el.tradeGroup}`,
            el.comment
          )
        );
      });
      const ws = XLSX.utils.json_to_sheet(newObjReport, accointingHeader);

      XLSX.utils.book_append_sheet(accointingWorkbook, ws, 'Table1');
      XLSX.writeFile(accointingWorkbook, 'Accointing_report.xlsx');
    }
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};
