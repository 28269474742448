let LASTCALL;
export const getHistoricalTokenPrice = async function (token, oracle) {
  try {
    let reqString = `https://ocean.defichain.com/v0/mainnet/oracles/${oracle}/${token}/feed`;
    LASTCALL = reqString;
    reqString += '?size=200';
    const respInfo = await fetch(reqString);
    if (!respInfo.ok) {
      throw new Error(`Problem fetching the prices`);
    }
    const dataBlock = await respInfo.json();
    return dataBlock;
  } catch (err) {
    console.error(err);
    alert(`Something went wrong getting Block Infos\n ${err.message}`);
    throw err;
  }
};

/**
 * Gets all information about the Liquidity Mining Pool Pairs from the defichain API
 * @returns the data with all pool pair infos
 */
export const getPoolPairInfos = async function () {
  try {
    const erg = [];
    const apiCall = `https://ocean.defichain.com/v0/mainnet/poolpairs`;
    const resData = await fetch(apiCall);
    if (!resData.ok)
      throw new Error(`Problem getting status data!\n ${resData.ok}`);
    const dataStatus = await resData.json();
    erg.push(...dataStatus.data);
    let isNextPage = false;
    if (dataStatus.page) {
      isNextPage = true;
    }
    let nextPage = dataStatus.page.next;
    while (isNextPage) {
      let nextApi = `${apiCall}?next=${nextPage}`;
      const nextResData = await fetch(nextApi);
      if (!resData.ok)
        throw new Error(`Problem getting status data!\n ${nextResData.ok}`);
      const apiResp = await nextResData.json();
      erg.push(...apiResp.data);
      if (apiResp.page) {
        nextPage = apiResp.page.next;
      } else {
        isNextPage = false;
      }
    }
    return erg;
  } catch (err) {
    console.error(err);
    alert(
      `Something went wrong fetching the Pool-Pair Infos - \n ${err.message}`
    );
    throw err;
  }
};

/**
 * Gets all Prices from the defichain price oracle (paged)
 * @returns the data with all prices supported from defichain
 */
export const getOraclePrices = async function () {
  try {
    const erg = [];
    const apiCall = `https://ocean.defichain.com/v0/mainnet/prices`;
    const resData = await fetch(apiCall);
    if (!resData.ok)
      throw new Error(`Problem getting status data!\n ${resData.ok}`);
    const prices = await resData.json();
    erg.push(...prices.data);
    let isNextPage = false;
    if (prices.page) {
      isNextPage = true;
    }
    let nextPage = prices.page.next;
    while (isNextPage) {
      let nextApi = `${apiCall}?next=${nextPage}`;
      const nextResData = await fetch(nextApi);
      if (!resData.ok)
        throw new Error(`Problem getting status data!\n ${nextResData.ok}`);
      const apiResp = await nextResData.json();
      erg.push(...apiResp.data);
      if (apiResp.page) {
        nextPage = apiResp.page.next;
      } else {
        isNextPage = false;
      }
    }
    return erg;
  } catch (err) {
    console.error(err);
    alert(
      `Something went wrong while fetching data from price oracle - \n ${err.message}`
    );
    throw err;
  }
};

/**
 * Gets all Prices from the defichain price oracle
 * @returns the data with all prices supported from defichain
 */
export const getTokenPrice = async function (token) {
  try {
    const apiCall = `https://ocean.defichain.com/v0/mainnet/prices/${token}`;
    const resData = await fetch(apiCall);
    if (!resData.ok)
      throw new Error(`Problem getting status data!\n ${resData.ok}`);
    const prices = await resData.json();
    return prices.data.price.aggregated.amount;
  } catch (err) {
    console.error(err);
    alert(
      `Something went wrong while fetching data from price oracle - \n ${err.message}`
    );
    throw err;
  }
};

/**
 * If API call ist paged, this method gets the next page
 * @param {String} next - key for the next page
 * @returns the data of the next page
 */
export const getNextPage = async function (next) {
  try {
    const reqString = `${LASTCALL}?next=${next}`;
    const respInfo = await fetch(reqString);
    if (!respInfo.ok) {
      throw new Error(`Problem fetching the prices`);
    }
    const dataBlock = await respInfo.json();
    return dataBlock;
  } catch (err) {
    console.error(err);
    alert(`Something went wrong getting Infos\n ${err.message}`);
    throw err;
  }
};

/**
 * Gets all information about the Liquidity Mining Pool Pairs from the defichain API
 * @returns the data with all pool pair infos
 */
export const getAddressHistory = async function (address) {
  try {
    const erg = [];
    const apiCall = `https://ocean.mydefichain.com/v0/mainnet/address/${address}/history`;
    const resData = await fetch(apiCall);
    if (!resData.ok)
      throw new Error(`Problem getting status data!\n ${resData.ok}`);
    const dataStatus = await resData.json();
    erg.push(...dataStatus.data);
    let isNextPage = false;
    if (dataStatus.page) {
      isNextPage = true;
    }
    let nextPage = dataStatus.page.next;
    while (isNextPage) {
      let nextApi = `${apiCall}?next=${nextPage}`;
      const nextResData = await fetch(nextApi);
      if (!resData.ok)
        throw new Error(`Problem getting status data!\n ${nextResData.ok}`);
      const apiResp = await nextResData.json();
      erg.push(...apiResp.data);
      if (apiResp.page) {
        nextPage = apiResp.page.next;
      } else {
        isNextPage = false;
      }
    }
    return erg;
  } catch (err) {
    console.error(err);
    alert(
      `Something went wrong fetching the Address-History Infos - \n ${err.message}`
    );
    throw err;
  }
};
