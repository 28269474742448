import * as dateHelper from '../Helper/DateHelper.js';
import * as hlp from '../Helper/Helper.js';
import * as lmTool from './LMTools.js';
import * as Actions from '../Actions/Actions.js';
import * as mdl from '../model.js';

/**
 * Checks and tries to calculate the missing LM entries
 * @param {Array} entryArr - Array with the incomplete LM entries
 * @returns the completed LM transaction entry
 */
const checkAndCalculateMissingTransaction = function (entryArr) {
  const retArr = [];
  let indexOfToken = -1;
  let indexOfDFI = -1;
  let indexOfDUSD = -1;
  let indexOfOtherCoin = -1;
  let strPoolId = '';
  let missingAmount = 0;
  for (let i = 0; i < entryArr.length; i++) {
    if (entryArr[i].Cryptocurrency.indexOf('-') != -1) {
      //LM Token found
      indexOfToken = i;
      strPoolId = entryArr[i].Cryptocurrency;
    } else if (entryArr[i].Cryptocurrency === 'DFI') {
      indexOfDFI = i;
    } else if (entryArr[i].Cryptocurrency === 'DUSD') {
      indexOfDUSD = i;
    } else {
      indexOfOtherCoin = i;
    }
  }
  let newEntry = {};
  newEntry['Block'] = entryArr[0].Block;
  newEntry['BlockHash'] = entryArr[0].BlockHash;
  newEntry['Operation'] = entryArr[0].Operation;
  newEntry['PoolID'] = entryArr[0].PoolID;
  newEntry['Date'] = entryArr[0].Date;

  if (entryArr.length === 2) {
    console.log(entryArr);
    if (indexOfToken === -1) {
      //Token is missing
      newEntry.Amount = Math.sqrt(entryArr[0].Amount * entryArr[1].Amount);
      if (indexOfDFI === -1) {
        newEntry.Cryptocurrency = `${entryArr[indexOfOtherCoin].Cryptocurrency}-${entryArr[indexOfDUSD].Cryptocurrency}`;
      } else {
        if (indexOfDUSD === -1) {
          newEntry.Cryptocurrency = `${entryArr[indexOfOtherCoin].Cryptocurrency}-${entryArr[indexOfDFI].Cryptocurrency}`;
        } else {
          newEntry.Cryptocurrency = `${entryArr[indexOfDFI].Cryptocurrency}-${entryArr[indexOfDUSD].Cryptocurrency}`;
        }
      }
    } else {
      //One coin is missing
      if (indexOfToken === 1) {
        newEntry.Amount =
          (entryArr[1].Amount * entryArr[1].Amount) / entryArr[0].Amount;
        newEntry.Cryptocurrency = entryArr[1].Cryptocurrency;
      } else {
        newEntry.Amount =
          (entryArr[0].Amount * entryArr[0].Amount) / entryArr[1].Amount;
        newEntry.Cryptocurrency = entryArr[0].Cryptocurrency;
      }
    }
  }
  newEntry.Owner = 'GENERATEDBYDEFICHAINREWARDHELPER';
  retArr.push(...entryArr);
  retArr.push(newEntry);

  return retArr;
};

/**
 * Determines alle LiquidityMining Actions (Add- / Remove-Pool)
 * @param {Array} lmData - Data with all transactions
 * @returns the array with all liquiditymining actions
 */
const getLMActionsArray = function (lmData) {
  //Set mit allen beteiligten Blöcken erstellen
  const blockHeight = new Set();
  lmData.forEach(el => {
    blockHeight.add(el.Block);
  });
  let arrOfEntryArr = [];
  //Für jeden beteiligten Block die Transaktionen ermitteln
  blockHeight.forEach(blockEl => {
    let entryArr = lmData.filter(entry => entry.Block === blockEl);
    if (entryArr.length > 3) {
      if (mdl.state.directBlockchain) {
        const txIds = new Set();
        entryArr.forEach(el => txIds.add(el.TxID));
        txIds.forEach(txEl => {
          let newFilter = entryArr.filter(entry => entry.TxID === txEl);
          if (newFilter.length === 3) {
            arrOfEntryArr.push(newFilter);
          } else {
            console.log('Undefined ERROR!!!');
            console.log(newFilter);
          }
        });
      } else {
        const poolStr = new Set();
        entryArr.forEach(el => poolStr.add(el.Pool));
        poolStr.forEach(poolEl => {
          let newFilter = entryArr.filter(entry => entry.Pool === poolEl);
          //If there are more than 3 actions within a block where not all actions are exported in "one line" we have to investigate a bit more
          if (newFilter.length > 3) {
            //Create a unique list with all involved Pools

            const suggestedPools = new Set();
            newFilter.forEach(el => {
              if (el.PoolSuggestion.length > 0) {
                suggestedPools.add(el.PoolSuggestion);
              }
            });
            suggestedPools.forEach(pool => {
              //Filter all elements with the same pool suggestion
              let entries = newFilter.filter(el => el.PoolSuggestion === pool);
              //If result contains 3 entries, everything is fine
              if (entries.length === 3) {
                arrOfEntryArr.push(entries);
              } else {
                //Is not, there was a problem with the assignment
                if (entries.length === 2) {
                  //One entry (DFI is missing)
                  let arrNotClear = newFilter.filter(
                    entry => entry.PoolSuggestion === ''
                  );
                  //If array with unclear elements is one 1 element long, everything is clear
                  if (arrNotClear.length === 1) {
                    entries.push(...arrNotClear);
                    arrOfEntryArr.push(entries);
                  } else {
                    //If not.. we have to do some more research
                    let fiatValue;
                    //Get the FIAT value from the other pool pair coin
                    if (entries[0].Cryptocurrency.length > 4) {
                      fiatValue = entries[1].Amount * entries[1].FiatPriceAPI;
                    } else {
                      fiatValue = entries[0].Amount * entries[0].FiatPriceAPI;
                    }
                    //Get all prices of the unclear elements...
                    arrNotClear.forEach(el => {
                      let lowerValue = Math.abs(fiatValue - fiatValue * 0.08);
                      let upperValue = Math.abs(fiatValue * 1.08);
                      let checkValue = Math.abs(el.Amount * el.FiatPriceAPI);
                      //console.log(`Folgende Werte: Lower: ${lowerValue} // Check: ${checkValue} // Upper: ${upperValue}`);
                      //...and check if the value fits to the other values
                      if (
                        checkValue >= lowerValue &&
                        checkValue <= upperValue
                      ) {
                        //If value fits, add the unclear element to the other entries
                        entries.push(el);
                        arrOfEntryArr.push(entries);
                      }
                    });
                  }
                } else {
                  //This means, we have a Problem with the suggestion...
                  console.log('Undefined ERROR!!!');
                  console.log(entries);
                }
              }
            });
            //Check if all entries are processed
            //TBD.
          } else {
            arrOfEntryArr.push(newFilter);
          }
        });
      }
    } else {
      if (entryArr.length < 3) {
        const newEntry = checkAndCalculateMissingTransaction(entryArr);
        arrOfEntryArr.push(newEntry);
      } else {
        arrOfEntryArr.push(entryArr);
      }
    }
  });

  return arrOfEntryArr;
};

/**
 *
 * @param {*} addData
 * @param {*} removeData
 */
const generateDeFiLMActionsWithToken = async function (
  addData,
  removeData,
  tool
) {
  let objData = [];
  //Generate LM Actions for DeFiApp file
  if (addData.length > 0 || removeData.length > 0) {
    hlp.logconsole('Generating LM actions for DeFi App');
    let allLiq = [...addData, ...removeData];
    allLiq = hlp.removeDublicates(allLiq);
    const arrOfEntryArr = getLMActionsArray(allLiq);
    //console.log(arrOfEntryArr);
    arrOfEntryArr.forEach(entryArr => {
      let addString = '';
      //Must have length 3 (Coin1, Coin2, LM-Token)
      //Length === 3 --> Everything is fine!
      if (entryArr.length === 3) {
        entryArr.forEach(el => {
          if (el.Owner === 'GENERATEDBYDEFICHAINREWARDHELPER') {
            addString = '- Entry was calculated by DeFiChain-Rewardhelper!';
          }
        });
        let coinsAmount = [];
        let lmAmount;
        let coinsCur = [];
        let lmCur;
        const entryDate = entryArr[0].Date;
        if (entryArr[0].Operation === 'AddPoolLiquidity') {
          let feeAmnt = Math.abs(entryArr[0].Fee).toFixed(14);
          let feeCurrency = entryArr[0].FeeCurrency;

          //<0 --> Coin || >0 --> Token
          entryArr.forEach(el => {
            if (el.Amount < 0) {
              coinsAmount.push(Math.abs(el.Amount).toFixed(14));
              coinsCur.push(el.Cryptocurrency);
            } else {
              lmAmount = Math.abs(el.Amount).toFixed(14);
              lmCur = el.Cryptocurrency;
            }
          });
          let actionName = 'Add Liquidity';
          if (tool === 'Koinly') {
            actionName = 'Liquidity in';
          }
          //Trade 1
          objData.push(
            Actions.buildEntryObj(
              'Trade',
              'trade',
              'order',
              '',
              lmAmount / 2,
              lmCur,
              coinsAmount[0],
              coinsCur[0],
              feeAmnt / 2,
              feeCurrency,
              'DeFiChain Wallet',
              actionName,
              `Trade 1 ${addString}`,
              entryDate,
              `1${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                entryArr[0].TxID
              }`,
              ''
            )
          );
          //Trade 2
          objData.push(
            Actions.buildEntryObj(
              'Trade',
              'trade',
              'order',
              '',
              lmAmount / 2,
              lmCur,
              coinsAmount[1],
              coinsCur[1],
              feeAmnt / 2,
              feeCurrency,
              'DeFiChain Wallet',
              actionName,
              `Trade 2 ${addString}`,
              entryDate,
              `2${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                entryArr[0].TxID
              }`,
              ''
            )
          );
        } else {
          let feeAmnt = Math.abs(entryArr[0].Fee).toFixed(14);
          let feeCurrency = entryArr[0].FeeCurrency;
          //>0 --> Coin || <0 --> Token
          entryArr.forEach(el => {
            if (el.Amount > 0) {
              coinsAmount.push(Math.abs(el.Amount).toFixed(14));
              coinsCur.push(el.Cryptocurrency);
            } else {
              lmAmount = Math.abs(el.Amount).toFixed(14);
              lmCur = el.Cryptocurrency;
            }
          });
          let actionName = 'Remove Liquidity';
          if (tool === 'Koinly') {
            actionName = 'Liquidity out';
          }
          //Trade zusammenstellen
          //Trade 1
          objData.push(
            Actions.buildEntryObj(
              'Trade',
              'trade',
              'order',
              '',
              coinsAmount[0],
              coinsCur[0],
              lmAmount / 2,
              lmCur,
              feeAmnt / 2,
              feeCurrency,
              'DeFiChain Wallet',
              actionName,
              `Trade 1 ${addString}`,
              entryDate,
              `1${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                entryArr[0].TxID
              }`,
              ''
            )
          );
          //Trade 2
          objData.push(
            Actions.buildEntryObj(
              'Trade',
              'trade',
              'order',
              '',
              coinsAmount[1],
              coinsCur[1],
              lmAmount / 2,
              lmCur,
              feeAmnt / 2,
              feeCurrency,
              'DeFiChain Wallet',
              actionName,
              `Trade 2 ${addString}`,
              entryDate,
              `2${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                entryArr[0].TxID
              }`,
              ''
            )
          );
        }
      } else {
        alert(
          `It seems that there is a problem with the Liquidity Minining in Block ${entryArr[0].Block}! Please ensure, that all addresses are processed at the same time. Otherwise please contact Marcus!`
        );
        console.log(
          `Error in generating LiquidityMining entries for Block ${entryArr[0].Block}`
        );
      }
    });
  }
  return objData;
};

/**
 *
 * @param {*} addData
 * @param {*} removeData
 */
const generateDeFiLMActionsWithOutToken = async function (addData, removeData) {
  let objData = [];
  //Do not use token and generate trades Coin --> FIAT --> Coin
  //Add Actions
  for (let i = 0; i < addData.length; i++) {
    const el = addData[i];
    if (el.Cryptocurrency.indexOf('-') === -1) {
      let fiatCur = 'EUR';
      let apiDate = new Date(
        Date.UTC(el.Date.getFullYear(), el.Date.getMonth(), el.Date.getDate())
      );

      let coinPrice = await mdl.getPriceForDate(el.Cryptocurrency, apiDate);
      //console.log(`Date: ${apiDate.getTime()} - Price: ${coinPrice}`);
      let coinAmount = Math.abs(el.Amount);
      let coinCur = el.Cryptocurrency;
      //console.log(`Amount: ${coinAmount} - Coin: ${coinCur}`);
      let fiatAmount = Math.abs(coinPrice * el.Amount).toFixed(2);
      //Trades zusammenstellen
      //Trade 1
      objData.push(
        Actions.buildEntryObj(
          'Trade',
          'trade',
          'order',
          '',
          fiatAmount,
          fiatCur,
          coinAmount,
          coinCur,
          '',
          '',
          'DeFiChain Wallet',
          'Add Liquidity',
          'Coin to Fiat',
          el.Date,
          `1${coinCur}${dateHelper.getTxIdDateString(el.Date)}`,
          ''
        )
      );
      //Trade 2
      objData.push(
        Actions.buildEntryObj(
          'Trade',
          'trade',
          'order',
          '',
          coinAmount,
          coinCur,
          fiatAmount,
          fiatCur,
          '',
          '',
          'DeFiChain Wallet',
          'Add Liquidity',
          'Fiat to LM Coins',
          el.Date,
          `2${coinCur}${dateHelper.getTxIdDateString(el.Date)}`,
          ''
        )
      );
    }
  }
  //Alle Aktionen zusammenfassen
  const defiActions = [...addData, ...removeData];
  const arrOfEntryArr = getLMActionsArray(defiActions);
  const lmActionsArr = [];

  arrOfEntryArr.forEach(entryArr => {
    //Must have length 3 (Coin1, Coin2, LM-Token)
    if (entryArr.length === 3) {
      const tempEl = {};
      tempEl.Date = entryArr[0].Date;

      let idxBase = entryArr.findIndex(el => el.Cryptocurrency === 'DFI');
      let idxToken = entryArr.findIndex(
        el => el.Cryptocurrency.indexOf('-') !== -1
      );
      let idxCoin1;
      //If DFI is not found, we have a Stock-LM-Pool. So we need to search for DUSD
      if (idxBase === -1) {
        //Stock-Token Pool
        idxBase = entryArr.findIndex(el => el.Cryptocurrency === 'DUSD');
        idxCoin1 = entryArr.findIndex(
          el =>
            el.Cryptocurrency !== 'DUSD' &&
            el.Cryptocurrency.indexOf('-') === -1
        );
      } else {
        //Crypto-Pool
        idxCoin1 = entryArr.findIndex(
          el =>
            el.Cryptocurrency !== 'DFI' && el.Cryptocurrency.indexOf('-') === -1
        );
      }

      tempEl.Action = entryArr[idxToken].Amount < 0 ? 'remove' : 'add';
      tempEl.Token = entryArr[idxToken].Cryptocurrency;
      tempEl.TokenAmount = entryArr[idxToken].Amount;
      tempEl.Coin1 = entryArr[idxCoin1].Cryptocurrency;
      tempEl.Coin2 = entryArr[idxBase].Cryptocurrency;
      tempEl.Coin1Amount = entryArr[idxCoin1].Amount;
      tempEl.Coin2Amount = entryArr[idxBase].Amount;
      tempEl.TokenPriceCoin1 =
        entryArr[idxCoin1].Amount / entryArr[idxToken].Amount;
      tempEl.TokenPriceCoin2 =
        entryArr[idxBase].Amount / entryArr[idxToken].Amount;

      lmActionsArr.push(tempEl);
    } else {
      alert(
        `It seems that there is a problem with the Liquidity Minining in Block ${entryArr[0].Block}! Please ensure, that all addresses are processed at the same time. Otherwise please contact Marcus!`
      );
      console.log(
        `Error in generating LiquidityMining entries for Block ${entryArr[0].Block}`
      );
    }
  });
  //console.log(lmActionsArr);
  //1.) Die Liquidity Aktionen müssen nach Datum aufsteigend sortiert werden
  lmActionsArr.sort(lmTool.sortDateAscending);
  //2.) Prüfen und ermitteln, ob es verschiedene Pools gibt
  const poolSet = new Set();
  lmActionsArr.forEach(el => poolSet.add(el.Token));
  const pools = Array.from(poolSet);
  //3.) Für jeden Pool muss das remove separat durchgeführt werden
  for (let i = 0; i < pools.length; i++) {
    let pool = pools[i];
    //console.log(pool);
    //Alle Aktionen für den aktuellen Pool ermitteln
    const lmPoolLmActions = lmActionsArr.filter(el => pool === el.Token);
    const remArrIdx = [];
    //Alle indizes von remove-Aktionen in einem Array speichern
    lmPoolLmActions.forEach((el, i) => {
      if (el.Action === 'remove') {
        remArrIdx.push(i);
      }
    });
    //Für alle remove Aktionen...
    for (let j = 0; j < remArrIdx.length; j++) {
      let el = remArrIdx[j];
      let c1Amnt = 0;
      let c2Amnt = 0;
      let tokenAmnt = 0;

      for (let k = 0; k < el; k++) {
        c1Amnt += Math.abs(lmPoolLmActions[k].Coin1Amount);
        c2Amnt += Math.abs(lmPoolLmActions[k].Coin2Amount);
        tokenAmnt += Math.abs(lmPoolLmActions[k].TokenAmount);
      }

      let c1SellAmnt = Math.abs(lmPoolLmActions[el].Coin1Amount);
      let c1 = lmPoolLmActions[el].Coin1;
      let c2SellAmnt = Math.abs(lmPoolLmActions[el].Coin2Amount);
      let c2 = lmPoolLmActions[el].Coin2;
      let date = lmPoolLmActions[el].Date;
      let c1Fiat = 0;
      let c2Fiat = 0;
      let fiatCur = 'EUR';
      let apiDate = new Date(
        Date.UTC(
          lmPoolLmActions[el].Date.getFullYear(),
          lmPoolLmActions[el].Date.getMonth(),
          lmPoolLmActions[el].Date.getDate()
        )
      );

      let c1Price = await mdl.getPriceForDate(c1, apiDate);
      let c2Price = await mdl.getPriceForDate(c2, apiDate);
      //console.log(`Date: ${apiDate.getTime()} - Price: ${coinPrice}`);
      //console.log(`Price ${c1}: ${c1Price} ${c1Amnt}`);
      //console.log(`Price ${c2}: ${c2Price} ${c2Amnt}`);
      c1Fiat = c1Price * c1SellAmnt;
      c2Fiat = c2Price * c2SellAmnt;

      //Trades zusammenstellen
      //Trade coin1Amount --> FIAT
      objData.push(
        Actions.buildEntryObj(
          'Trade',
          'trade',
          'order',
          '',
          c1Fiat,
          fiatCur,
          c1SellAmnt,
          c1,
          '',
          '',
          'DeFiChain Wallet',
          'Remove Liquidity',
          'Coin1 to Fiat',
          date,
          `1${c1}${dateHelper.getTxIdDateString(date)}`,
          ''
        )
      );
      //Trade coin2Amount --> FIAT
      objData.push(
        Actions.buildEntryObj(
          'Trade',
          'trade',
          'order',
          '',
          c2Fiat,
          fiatCur,
          c2SellAmnt,
          c2,
          '',
          '',
          'DeFiChain Wallet',
          'Remove Liquidity',
          'Coin2 to Fiat',
          date,
          `2${c2}${dateHelper.getTxIdDateString(date)}`,
          ''
        )
      );
      //Trade FIAT --> coin1Amount
      objData.push(
        Actions.buildEntryObj(
          'Trade',
          'trade',
          'order',
          '',
          c1SellAmnt,
          c1,
          c1Fiat,
          fiatCur,
          '',
          '',
          'DeFiChain Wallet',
          'Remove Liquidity',
          'Fiat to Coin1',
          date,
          `1${c1}${dateHelper.getTxIdDateString(date)}`,
          ''
        )
      );
      //Trade FIAT --> coin2Amount
      objData.push(
        Actions.buildEntryObj(
          'Trade',
          'trade',
          'order',
          '',
          c2SellAmnt,
          c2,
          c2Fiat,
          fiatCur,
          '',
          '',
          'DeFiChain Wallet',
          'Remove Liquidity',
          'Fiat to Coin2',
          date,
          `2${c2}${dateHelper.getTxIdDateString(date)}`,
          ''
        )
      );

      //Wieviele Coins hätte ich eigentlich bekommen müssen?
      //console.log(`Eingezahlt: ${c1Amnt} Auszahlen: ${c1SellAmnt} Token: ${tokenAmnt}`);
      let coin1Diff =
        (c1Amnt / tokenAmnt) * Math.abs(lmPoolLmActions[el].TokenAmount);
      let coin2Diff =
        (c2Amnt / tokenAmnt) * Math.abs(lmPoolLmActions[el].TokenAmount);
      coin1Diff = c1SellAmnt - coin1Diff;
      coin2Diff = c2SellAmnt - coin2Diff;
      let sellCoin;
      let sellCur;
      let buyCoin;
      let buyCur;
      if (coin1Diff < 0 && coin2Diff < 0) {
        objData.push(
          Actions.buildEntryObj(
            'Withdrawal',
            'withdrawal',
            'withdraw',
            'margin_loss',
            '',
            '',
            Math.abs(coin1Diff),
            c1,
            '',
            '',
            'DeFiChain Wallet',
            'Remove Liquidity',
            'Impermanent loss compensation Coin1',
            date,
            `${c1}${dateHelper.getTxIdDateString(date)}`,
            ''
          )
        );
        objData.push(
          Actions.buildEntryObj(
            'Withdrawal',
            'withdrawal',
            'withdraw',
            'margin_loss',
            '',
            '',
            Math.abs(coin2Diff),
            c2,
            '',
            '',
            'DeFiChain Wallet',
            'Remove Liquidity',
            'Impermanent loss compensation Coin2',
            date,
            `${c2}${dateHelper.getTxIdDateString(date)}`,
            ''
          )
        );
      } else {
        if (coin1Diff < 0) {
          sellCoin = Math.abs(coin1Diff);
          sellCur = c1;
          buyCoin = Math.abs(coin2Diff);
          buyCur = c2;
        } else {
          sellCoin = Math.abs(coin2Diff);
          sellCur = c2;
          buyCoin = Math.abs(coin1Diff);
          buyCur = c1;
        }
        objData.push(
          Actions.buildEntryObj(
            'Trade',
            'trade',
            'order',
            '',
            buyCoin,
            buyCur,
            sellCoin,
            sellCur,
            '',
            '',
            'DeFiChain Wallet',
            'Remove Liquidity',
            'Impermanent loss compensation',
            date,
            `${sellCur}${dateHelper.getTxIdDateString(date)}`,
            ''
          )
        );
      }
    }
  }
  return objData;
};

/**
 * Generates the defichain liquidity mining entries
 * @param {Array} data
 * @param {String} toolid
 
 */
export const generateDeFiLMActions = async function (data, useToken, tool) {
  let objData = [];
  let addDefiLMActions = lmTool.getAddDefiLMActions(data);
  let remDefiLMActions = lmTool.getRemDefiLMActions(data);
  if (useToken) {
    objData = generateDeFiLMActionsWithToken(
      addDefiLMActions,
      remDefiLMActions,
      tool
    );
  } else {
    objData = await generateDeFiLMActionsWithOutToken(
      addDefiLMActions,
      remDefiLMActions
    );
  }

  return objData;
};
