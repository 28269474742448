import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Generates the report data obj for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @returns returns the objs
 */
export const generateCakeTrades = function (data) {
  let objData = [];
  hlp.logconsole('Generating Trade Actions');

  const inArr = data.filter(el => el.Operation === 'Swapped in');
  const outArr = data.filter(el => el.Operation === 'Swapped out');
  if (outArr.length !== inArr.length) {
    alert(
      `Error: Swapped in and Swapped out did not match!\n\nPlease ensure, that you selected all relevant files for upload!\n Hint: If you bought DFI Coins on Cake you have to upload the BTC and / or ETH CSV file as well!`
    );
  } else {
    //Check elements which belongs together
    inArr.forEach(iEl => {
      outArr.forEach(oEl => {
        const iAmount = Math.abs(iEl.Amount).toFixed(14);
        const oAmount = Math.abs(oEl.Amount).toFixed(14);
        const iCur = iEl.Cryptocurrency;
        const oCur = oEl.Cryptocurrency;
        if (iEl.RelatedReference === oEl.RelatedReference) {
          //Match found
          objData.push(
            Actions.buildEntryObj(
              'Trade',
              'trade',
              'order',
              '',
              iAmount,
              iCur,
              oAmount,
              oCur,
              '',
              '',
              'CAKE',
              'CAKE Internal Trade',
              'Currency Swap',
              iEl.Date,
              `${iCur}${oCur}${dateHelper.getTxIdDateString(iEl.Date)}`,
              ''
            )
          );
        }
      });
    });
  }
  return objData;
};

/**
 * Generates the report data obj for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @returns returns the objs
 */
export const generateCakeTrades2 = function (data) {
  let objData = [];
  hlp.logconsole('Generating Trade Actions 2');

  //1.) Get all entries where the related reference is not empty
  const possibleTradesList = data.filter(el => el.RelatedReference != '');

  //2.) Now, get only the entries with operation "Deposit", "Withdrew for swap" and "Paid swap fee"
  const relevantEntries = possibleTradesList.filter(el => {
    if (
      el.Operation === 'Deposit' ||
      el.Operation === 'Withdrew for swap' ||
      el.Operation === 'Paid swap fee'
    ) {
      return true;
    } else {
      return false;
    }
  });

  //3.) Create a unique list with all different related references
  const relRef = new Set();
  relevantEntries.forEach(el => {
    relRef.add(el.RelatedReference);
  });

  //4.) For each related reference get all corresponding transactions and process them as a trade
  relRef.forEach(ref => {
    const entries = relevantEntries.filter(
      entry => entry.RelatedReference === ref
    );
    if (entries.length < 3) {
      console.error('Hier ist was kaputt');
      return;
    }
    //4.a) Identify the trade parts
    let buyEntry = '';
    let sellEntries = [];
    entries.forEach(el => {
      if (el.Amount < 0) {
        sellEntries.push(el);
      } else {
        buyEntry = el;
      }
    });

    //4.b) Check which part the buy and which part the sell / fee ist
    let sellEntry;
    let feeEntry;
    if (sellEntries[0].Amount > sellEntries[1].Amount) {
      sellEntry = sellEntries[1];
      feeEntry = sellEntries[0];
    } else {
      sellEntry = sellEntries[0];
      feeEntry = sellEntries[1];
    }

    //Build the trade
    objData.push(
      Actions.buildEntryObj(
        'Trade',
        'trade',
        'order',
        '',
        Math.abs(buyEntry.Amount).toFixed(14),
        buyEntry.Cryptocurrency,
        Math.abs(sellEntry.Amount + feeEntry.Amount).toFixed(14),
        sellEntry.Cryptocurrency,
        Math.abs(feeEntry.Amount).toFixed(14),
        feeEntry.Cryptocurrency,
        'CAKE',
        'CAKE Internal Trade',
        'Currency Swap',
        buyEntry.Date,
        `${buyEntry.Cryptocurrency}${
          sellEntry.Cryptocurrency
        }${dateHelper.getTxIdDateString(buyEntry.Date)}`,
        ''
      )
    );
  });
  return objData;
};
