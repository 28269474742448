import * as cfg from '../Helper/config.js';
import * as dateHelper from '../Helper/DateHelper.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Groups the data and returns it
 * @param {Array} data
 * @param {String} groupingType - 'none', 'day' or 'month'
 * @returns the grouped entries
 */
export const getGroupedData = function (data, groupingType) {
  const retArr = [];
  //1.) Get the data which is groupable
  let groupedData = hlp.getGroupableData(data);

  //2.) Group the data
  if (groupingType === 'none') {
    groupedData.forEach(arr => {
      arr.forEach(el => {
        retArr.push(el);
      });
    });
  }
  if (groupingType === 'day') {
    groupedData.forEach(arr => {
      dateHelper.getListOfDays(arr).forEach(el => {
        let erg = dateHelper.getListForDays(arr, el);
        retArr.push(hlp.getGroupedEntry(erg, ''));
      });
    });
  }
  if (groupingType === 'month') {
    groupedData.forEach(arr => {
      dateHelper.getListOfMonth(arr).forEach(el => {
        let erg = dateHelper.getListForMonth(arr, el);
        retArr.push(hlp.getGroupedEntry(erg, ''));
      });
    });
  }
  return retArr;
};

///////////////////////////////////////////////////////////////////////////////
/**
 * Builds an object with all given parameters and returns it
 * @param {String} transaction
 * @param {String} date
 * @param {Number} inAmount
 * @param {String} inAsset
 * @param {Number} outAmount
 * @param {String} outAsset
 * @param {Number} feeAmount
 * @param {String} feeAsset
 * @param {String} classId
 * @param {String} opId
 */
export const getObjectFromData = function (
  transaction,
  date,
  inAmount,
  inAsset,
  outAmount,
  outAsset,
  feeAmount,
  feeAsset,
  classId,
  opId,
  comment
) {
  let elem = {};
  elem['transactionType'] = transaction;
  elem['date'] = date;
  elem['inBuyAmount'] = inAmount;
  elem['inBuyAsset'] = inAsset;
  if (classId === 'fee') {
    elem['outSellAmount'] = feeAmount;
    elem['outSellAsset'] = feeAsset;
    elem['feeAmount (optional)'] = '';
    elem['feeAsset (optional)'] = '';
  } else {
    elem['outSellAmount'] = outAmount;
    elem['outSellAsset'] = outAsset;
    elem['feeAmount (optional)'] = feeAmount;
    elem['feeAsset (optional)'] = feeAsset;
  }

  elem['classification (optional)'] = classId;
  elem['operationId (optional)'] = opId;
  elem['comments (optional)'] = comment;

  return elem;
};

/**
 * Builds an object with all given parameters and returns it
 * @param {String} type
 * @param {Number} buyAmount
 * @param {String} buyCur
 * @param {Number} sellAmount
 * @param {String} sellCur
 * @param {Number} feeAmount
 * @param {String} feeCur
 * @param {String} exchange
 * @param {String} tradeGroup
 * @param {String} comment
 * @param {String} date
 * @param {String} txId
 * @param {String} clarification
 */
export const buildEntryObj = function (
  cointracking_type,
  blockpit_type,
  accointing_type,
  accointing_clarification,
  buyAmount,
  buyCur,
  sellAmount,
  sellCur,
  feeAmount,
  feeCur,
  exchange,
  tradeGroup,
  comment,
  date,
  txId
) {
  let elem = {};
  elem['cointracking_type'] = cointracking_type;
  elem['blockpit_type'] = blockpit_type;
  elem['accointing_type'] = accointing_type;
  elem['accointing_clarification'] = accointing_clarification;
  elem['buyAmount'] = buyAmount;
  elem['buyCur'] = buyCur;
  elem['sellAmount'] = sellAmount;
  elem['sellCur'] = sellCur;
  elem['feeAmount'] = feeAmount;
  elem['feeCur'] = feeCur;
  elem['exchange'] = exchange;
  elem['tradeGroup'] = tradeGroup;
  elem['comment'] = comment;
  elem['date'] = date;
  elem['txId'] = txId;

  return elem;
};

/**
 * Removes unneeded token transactions (AccountToAccount, AnyAccountsToAccounts)
 * @param {Array} data - Data to be checked
 * @returns the array with "external" token transactionen
 */
const getNeededTokenTransactions = function (data) {
  const newArr = [];
  let bFound = false;
  data.forEach(checkEl => {
    bFound = false;
    data.forEach(el => {
      if (checkEl.Amount + el.Amount === 0) {
        //if (checkEl.Block === el.Block) {
        bFound = true;
        //}
      }
    });
    if (!bFound) {
      newArr.push(checkEl);
    }
  });
  return newArr;
};

const getNeededUTXOTransactions = function (dataUTXO, dataSendRec) {
  const newArr = [];
  let bFound = false;

  dataSendRec.forEach(checkEl => {
    bFound = false;
    dataUTXO.forEach(el => {
      if (checkEl.Operation === 'receive') {
        if (checkEl.Amount + el.Amount === 0) {
          if (checkEl.Date.getTime() === el.Date.getTime()) {
            bFound = true;
          }
        }
      }
    });
    if (!bFound) {
      newArr.push(checkEl);
    }
  });
  return newArr;
};

/**
 * Generates report for all action (csv or obj) for the given data
 * @param {Array} coinData - Data array with the data to be processed
 * @param {String} reportTool - Which tool to be used.
 * @param {String} groupingType - the grouping type. 'day', 'month' or 'none'
 * @returns the array with all action objects
 */
export const generateActions = function (coinData, groupingType) {
  if (coinData.length === 0) return '';

  const accountActionsArr = getNeededTokenTransactions(
    coinData.filter(el => el.Operation === 'AccountToAccount')
  );

  const anyAccountActionsArr = getNeededTokenTransactions(
    coinData.filter(el => el.Operation === 'AnyAccountsToAccounts')
  );

  const tempActionsArr = getNeededTokenTransactions(
    coinData.filter(el => {
      if (el.Operation === 'sent' || el.Operation === 'receive') {
        return true;
      } else {
        return false;
      }
    })
  );

  //TODO_1009: with the new structure this part ist not needed any more
  /*const sentReceiveActionsArr = getNeededUTXOTransactions(
    coinData.filter(el => el.Operation === 'AccountToUtxos'),
    tempActionsArr
  );*/

  const sentReceiveActionsArr = coinData.filter(
    el => el.Operation === 'AccountToUtxos'
  );

  const utxosToAccountActionsArr = coinData.filter(
    el => el.Operation === 'UtxosToAccount'
  );
  //console.log(sentReceiveActionsArr);
  const filteredCoinData = coinData.filter(el => {
    if (
      el.Operation === 'AccountToAccount' ||
      el.Operation === 'AnyAccountsToAccounts' ||
      el.Operation === 'sent' ||
      el.Operation === 'receive' ||
      el.Operation === 'AccountToUtxos' ||
      el.Operation === 'UtxosToAccount'
    ) {
      return false;
    }
    return true;
  });
  const newArr = [
    ...getGroupedData(filteredCoinData, groupingType),
    ...hlp.getNonGroupableData(filteredCoinData),
    ...accountActionsArr,
    ...anyAccountActionsArr,
    ...sentReceiveActionsArr,
    ...tempActionsArr,
    ...utxosToAccountActionsArr,
  ];
  let objData = [];
  newArr.forEach(data => {
    if (data.Operation === 'Deposit' && data.RelatedReference.length > 0) {
      return '';
    }
    let strOperation = '';
    if (data.Operation.indexOf('Liquidity mining reward') !== -1) {
      strOperation = 'Liquidity mining reward';
    } else if (data.Operation.indexOf('Remove liquidity fee') !== -1) {
      strOperation = 'Remove liquidity fee';
    } else {
      strOperation = data.Operation;
    }

    const opCfg = cfg.CSVMAPPING.get(strOperation);
    if (!opCfg) return '';

    const isNegative = Number(data.Amount) < 0;
    const amount = Math.abs(data.Amount).toFixed(14);
    let cur = data.Cryptocurrency;
    const opId = data.Operation;

    if (data.Cryptocurrency === 'XZC') {
      cur = 'FIRO';
    }

    //inAmount, inCur, outAmount, outCur, feeAmount, feeCur
    let valArr = ['', '', '', '', '', ''];

    //If opCfg.inout is true we have to check if it´s a desposit or not
    if (opCfg.inout) {
      if (isNegative) {
        opCfg.deposit = false;
        opCfg.cointrackingid = 'Withdrawal';
        opCfg.blockpitid = 'withdrawal';
        opCfg.accointingid = 'withdraw';
      } else {
        opCfg.deposit = true;
        opCfg.cointrackingid = 'Deposit';
        opCfg.blockpitid = 'deposit';
        opCfg.accointingid = 'deposit';
      }
    }
    if (opCfg.fee) {
      valArr = ['', '', '', '', amount, cur];
    } else {
      if (opCfg.deposit) {
        valArr = [amount, cur, '', '', '', ''];
      } else {
        valArr = ['', '', amount, cur, '', ''];
      }
    }
    objData.push(
      buildEntryObj(
        opCfg.cointrackingid,
        opCfg.blockpitid,
        opCfg.accointingid,
        opCfg.accointing_class,
        valArr[0],
        valArr[1],
        valArr[2],
        valArr[3],
        valArr[4],
        valArr[5],
        opCfg.exchange,
        '',
        data.Operation,
        data.Date,
        `${cur}_${amount}_${dateHelper.getTxIdDateString(data.Date)}_${opId}`
      )
    );
  });

  return objData;
};
